body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* Black */
@font-face {
  font-family: 'NotoSansKR';
  font-style: normal;
  font-weight: 900; 
  src: url("./fonts/NotoSansKR/NotoSansKR-Black.woff2") format('woff2'),
  url("./fonts/NotoSansKR/NotoSansKR-Black.woff") format('woff'),
  url("./fonts/NotoSansKR/NotoSansKR-Black.otf") format('truetype')
}
/* Bold */
@font-face {
  font-family: 'NotoSansKR';
  font-style: normal;
  font-weight: 700;
  src: url("./fonts/NotoSansKR/NotoSansKR-Bold.woff2") format('woff2'),
  url("./fonts/NotoSansKR/NotoSansKR-Bold.woff") format('woff'),
  url("./fonts/NotoSansKR/NotoSansKR-Bold.otf") format('truetype')
}
/* Medium */
@font-face {
  font-family: 'NotoSansKR';
  font-style: normal;
  font-weight: 500;
  src: url("./fonts/NotoSansKR/NotoSansKR-Medium.woff2") format('woff2'),
  url("./fonts/NotoSansKR/NotoSansKR-Medium.woff") format('woff'),
  url("./fonts/NotoSansKR/NotoSansKR-Medium.otf") format('truetype')
}
/* Regular */
@font-face {
  font-family: 'NotoSansKR';
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/NotoSansKR/NotoSansKR-Regular.woff2") format('woff2'),
  url("./fonts/NotoSansKR/NotoSansKR-Regular.woff") format('woff'),
  url("./fonts/NotoSansKR/NotoSansKR-Regular.otf") format('truetype')
}
/* Light */
@font-face {
  font-family: 'NotoSansKR';
  font-style: normal;
  font-weight: 300;
  src: url("./fonts/NotoSansKR/NotoSansKR-Light.woff2") format('woff2'),
  url("./fonts/NotoSansKR/NotoSansKR-Light.woff") format('woff'),
  url("./fonts/NotoSansKR/NotoSansKR-Light.otf") format('truetype')
}
/* DemiLight */
@font-face {
  font-family: 'NotoSansKR';
  font-style: normal;
  font-weight: 350;
  src: url("./fonts/NotoSansKR/NotoSansKR-DemiLight.woff2") format('woff2'),
  url("./fonts/NotoSansKR/NotoSansKR-DemiLight.woff") format('woff'),
  url("./fonts/NotoSansKR/NotoSansKR-DemiLight.otf") format('truetype')
}
/* Thin */
@font-face {
  font-family: 'NotoSansKR';
  font-style: normal;
  font-weight: 100;
  src: url("./fonts/NotoSansKR/NotoSansKR-Thin.woff2") format('woff2'),
  url("./fonts/NotoSansKR/NotoSansKR-Thin.woff") format('woff'),
  url("./fonts/NotoSansKR/NotoSansKR-Thin.otf") format('truetype')
}
/* Black */
@font-face {
  font-family: 'NotoSerifKR';
  font-style: normal;
  font-weight: 900;
  src: url("./fonts/NotoSerifKR/NotoSerifKR-Black.woff2") format('woff2'),
  url("./fonts/NotoSerifKR/NotoSerifKR-Black.woff") format('woff'),
  url("./fonts/NotoSerifKR/NotoSerifKR-Black.otf") format('truetype')
}
/* Bold */
@font-face {
  font-family: 'NotoSerifKR';
  font-style: normal;
  font-weight: 700;
  src: url("./fonts/NotoSerifKR/NotoSerifKR-Bold.woff2") format('woff2'),
  url("./fonts/NotoSerifKR/NotoSerifKR-Bold.woff") format('woff'),
  url("./fonts/NotoSerifKR/NotoSerifKR-Bold.otf") format('truetype')
}
/* Medium */
@font-face {
  font-family: 'NotoSerifKR';
  font-style: normal;
  font-weight: 500;
  src: url("./fonts/NotoSerifKR/NotoSerifKR-Medium.woff2") format('woff2'),
  url("./fonts/NotoSerifKR/NotoSerifKR-Medium.woff") format('woff'),
  url("./fonts/NotoSerifKR/NotoSerifKR-Medium.otf") format('truetype')
}
/* Regular */
@font-face {
  font-family: 'NotoSerifKR';
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/NotoSerifKR/NotoSerifKR-Regular.woff2") format('woff2'),
  url("./fonts/NotoSerifKR/NotoSerifKR-Regular.woff") format('woff'),
  url("./fonts/NotoSerifKR/NotoSerifKR-Regular.otf") format('truetype')
}
/* Light */
@font-face {
  font-family: 'NotoSerifKR';
  font-style: normal;
  font-weight: 300;
  src: url("./fonts/NotoSerifKR/NotoSerifKR-Light.woff2") format('woff2'),
  url("./fonts/NotoSerifKR/NotoSerifKR-Light.woff") format('woff'),
  url("./fonts/NotoSerifKR/NotoSerifKR-Light.otf") format('truetype')
}
/* ExtraLight */
@font-face {
  font-family: 'NotoSerifKR';
  font-style: normal;
  font-weight: 200;
  src: url("./fonts/NotoSerifKR/NotoSerifKR-ExtraLight.woff2") format('woff2'),
  url("./fonts/NotoSerifKR/NotoSerifKR-ExtraLight.woff") format('woff'),
  url("./fonts/NotoSerifKR/NotoSerifKR-ExtraLight.otf") format('truetype')
}
/* SemiBold */
@font-face {
  font-family: 'NotoSerifKR';
  font-style: normal;
  font-weight: 600;
  src: url("./fonts/NotoSerifKR/NotoSerifKR-SemiBold.woff2") format('woff2'),
  url("./fonts/NotoSerifKR/NotoSerifKR-SemiBold.woff") format('woff'),
  url("./fonts/NotoSerifKR/NotoSerifKR-semiBold.otf") format('truetype')
}
/* Normal */
@font-face {
  font-family: 'NanumBarunGothic';
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/NanumBarunGothic/NanumBarunGothic.woff2") format('woff2'),
  url("./fonts/NanumBarunGothic/NanumBarunGothic.woff") format('woff'),
  url("./fonts/NanumBarunGothic/NanumBarunGothic.otf") format('truetype')
}
/* Bold */
@font-face {
  font-family: 'NanumBarunGothic';
  font-style: normal;
  font-weight: 700;
  src: url("./fonts/NanumBarunGothic/NanumBarunGothicBold.woff2") format('woff2'),
  url("./fonts/NanumBarunGothic/NanumBarunGothicBold.woff") format('woff'),
  url("./fonts/NanumBarunGothic/NanumBarunGothicBold.otf") format('truetype')
}
/* Light */
@font-face {
  font-family: 'NanumBarunGothic';
  font-style: normal;
  font-weight: 300;
  src: url("./fonts/NanumBarunGothic/NanumBarunGothicLight.woff2") format('woff2'),
  url("./fonts/NanumBarunGothic/NanumBarunGothicLight.woff") format('woff'),
  url("./fonts/NanumBarunGothic/NanumBarunGothicLight.otf") format('truetype')
}
/* UltraLight */
@font-face {
  font-family: 'NanumBarunGothic';
  font-style: normal;
  font-weight: 100;
  src: url("./fonts/NanumBarunGothic/NanumBarunGothicUltraLight.woff2") format('woff2'),
  url("./fonts/NanumBarunGothic/NanumBarunGothicUltraLight.woff") format('woff'),
  url("./fonts/NanumBarunGothic/NanumBarunGothicUltraLight.otf") format('truetype')
}
/* Normal */
@font-face {
  font-family: 'NanumGothic';
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/NanumGothic/NanumGothic.woff2") format('woff2'),
  url("./fonts/NanumGothic/NanumGothic.woff") format('woff'),
  url("./fonts/NanumGothic/NanumGothic.otf") format('truetype')
}
/* Bold */
@font-face {
  font-family: 'NanumGothic';
  font-style: normal;
  font-weight: 700;
  src: url("./fonts/NanumGothic/NanumGothicBold.woff2") format('woff2'),
  url("./fonts/NanumGothic/NanumGothicBold.woff") format('woff'),
  url("./fonts/NanumGothic/NanumGothicBold.otf") format('truetype')
}
/* ExtraBold */
@font-face {
  font-family: 'NanumGothic';
  font-style: normal;
  font-weight: 800;
  src: url("./fonts/NanumGothic/NanumGothicExtraBold.woff2") format('woff2'),
  url("./fonts/NanumGothic/NanumGothicExtraBold.woff") format('woff'),
  url("./fonts/NanumGothic/NanumGothicExtraBold.otf") format('truetype')
}
/* Light */
@font-face {
  font-family: 'NanumGothic';
  font-style: normal;
  font-weight: 300;
  src: url("./fonts/NanumGothic/NanumGothicLight.woff2") format('woff2'),
  url("./fonts/NanumGothic/NanumGothicLight.woff") format('woff'),
  url("./fonts/NanumGothic/NanumGothicLight.otf") format('truetype')
}